.uikit-reset {
  font-family: 'Nunito', system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
}

.uikit-reset h1,
.uikit-reset h2,
.uikit-reset h3,
.uikit-reset h4,
.uikit-reset h5,
.uikit-reset h6 {
  font-weight: 600;
}

.uikit-reset p {
  line-height: 24px;
}

.shimmer::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    90deg,
    hsla(200 1% 98% / 0) 0%,
    hsla(200 1% 98% / 0.5) 50%,
    hsla(200 1% 98% / 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.scroll-shadows {
  background:
    /* Shadow Cover TOP */ linear-gradient(
        hsla(200 1% 98% / 1) 30%,
        rgba(255, 255, 255, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */
      linear-gradient(rgba(255, 255, 255, 0), hsla(200 1% 98% / 1) 70%) center
      bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 8px, 100% 8px;
  background-attachment: local, local, scroll, scroll;
}
